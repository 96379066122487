<template>
	<div>
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-47d637b5-633a-466d-bfef-bbf5b8682fd3.png">
					<div class="info">
						<div class="tit">客户群业绩</div>
						<div class="tip">在该页面，你可以看到每个企业微信客户群，群内客户的消费概况。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jump">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/客户群业绩</div>
				<img style="display: block;width:100%;max-width: 885px;"
					src="https://cdn.dkycn.cn/melyshop/image/20210803-b21b996c-d928-4a94-8a3e-340e1d57e156.png"></img>
					
				<div class="desc-title">2/统计指标说明文案</div>
				<div class="desc-content">
					<div>（1）当前群内客户数：当前还在企业微信客户群内，并且是微信客户的成员数量</div>
					<div>（2）下单笔数：群内成员-微信用户，在商城里创建的订单数量，不剔除已取消的订单</div>
					<div>（3）下单金额：群内成员-微信用户，在商城里创建的订单，订单总金额，包含运费，不剔除已取消的订单</div>
					<div>（4）支付笔数：群内成员-微信用户，在商城里支付成功的订单数量，不剔除已退款或已关闭的订单</div>
					<div>（5）支付金额：群内成员-微信用户，在商城里支付成功的订单，订单实付金额，包含运费，不剔除已退款的金额</div>
				</div>
			</div>
		</el-card>
		<el-card v-if="isauthorization">
			<div style="display:flex;flex-direction:row;flex-wrap:wrap">
				<div style="font-size:14px;line-height:37px;font-weight: bold;color: #606266;">关键字：</div>
				<el-input v-model="keywords" placeholder="群名称" style="margin:0px 10px;width:250px"></el-input>
				<div style="font-size:14px;line-height:37px;font-weight: bold;color: #606266;">群聊状态：</div>
				<el-select v-model="groupstate" placeholder="请选择群聊状态" style="margin:0px 10px;width:200px">
					<el-option v-for="(v,i) in groupstateList" :key="i" :value="v.value" :label="v.label"></el-option>
				</el-select>
				<el-button type="primary" style="wdith:90px;height:36px" @click="query">查询</el-button>
			</div>
		</el-card>
		<el-card v-if="isauthorization" style="margin-top:10px">
			<div>
				<el-table :data="tableList" v-loading="tableloading">
					<el-table-column prop="" label="群名称" width="250px">
						<template slot-scope="scope">
							<div class="FlexRow">
								<div style="width:50px;height:50px">
									<img :src="groupImg" style="width:100%;height:100%" alt="" />
								</div>
								<div style="margin-left:10px;height:50px;display:table;">
									<div style="display:table-cell;vertical-align:middle">
										<div
											style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp:2;overflow: hidden;width:160px">
											{{scope.row.GroupName}}
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="群主">
						<template slot-scope="scope">
							<el-tag>
								<i class="el-icon-user"></i>
								{{scope.row.EmployeeName}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="AddTime" label="建群时间"></el-table-column>
					<el-table-column prop="GroupCustomerCount" label="当前群内客户数">
						<template slot="header">
							<div class="FlexRow">
								<div>当前群内客户数</div>
								<el-tooltip class="item" effect="dark" content="群内所有微信客户数" placement="top">
									<i class="el-icon-warning-outline"
										style="font-size:18px;margin:2px 0px 0px 5px"></i>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PlaceOrderCount" label="下单笔数">
						<template slot="header">
							<div class="FlexRow">
								<div>下单笔数</div>
								<el-tooltip class="item" effect="dark" content="群内所有微信客户未退群时创建的订单总数，不剔除取消订单"
									placement="top">
									<i class="el-icon-warning-outline"
										style="font-size:18px;margin:2px 0px 0px 5px"></i>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PlaceOrderMoney" label="下单金额">
						<template slot="header">
							<div class="FlexRow">
								<div>下单金额</div>
								<el-tooltip class="item" effect="dark" content="群内所有微信客户未退群时创建的订单总金额，不剔除取消订单"
									placement="top">
									<i class="el-icon-warning-outline"
										style="font-size:18px;margin:2px 0px 0px 5px"></i>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderCount" label="支付笔数">
						<template slot="header">
							<div class="FlexRow">
								<div>支付笔数</div>
								<el-tooltip class="item" effect="dark" content="群内所有微信客户未退群时支付成功的订单总数量，不剔除退款"
									placement="top">
									<i class="el-icon-warning-outline"
										style="font-size:18px;margin:2px 0px 0px 5px"></i>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayOrderMoney" label="支付金额">
						<template slot="header">
							<div class="FlexRow">
								<div>支付金额</div>
								<el-tooltip class="item" effect="dark" content="群内所有微信客户未退群时成功支付的订单总额，不剔除退款"
									placement="top">
									<i class="el-icon-warning-outline"
										style="font-size:18px;margin:2px 0px 0px 5px"></i>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div style="margin-top:10px;text-align:right" v-if="total">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="sizepage"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		qyweixincutomerqyweixinrealgroupperformancelist,
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0"
	import config from '@/config/index'
	export default {
		data() {
			return {
				goUrls: config.GO_URL,
				isauthorization: true,
				defaultImg: "https://cdn.dkycn.cn/images/melyshop/employeeManageDefaultAvatar.png",
				groupImg: 'https://cdn.dkycn.cn/images/melyshop/grouplist.png',
				keywords: '',
				groupstate: false,
				groupstateList: [{
						value: false,
						label: '未解散'
					},
					{
						value: true,
						label: '已解散'
					},
				],

				tableList: [],
				tableloading: false,
				currentPage: 1,
				sizepage: 20,
				total: null,
			}
		},
		created() {
			this.Isqyweixinsuiteauthinfo()
		},

		methods: {
			query() {
				this.currentPage = 1
				this.getqyweixinrealgroupperformancelist()
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.sizepage = e
				this.getqyweixinrealgroupperformancelist()
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.getqyweixinrealgroupperformancelist()
			},
			jump() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},

			async getqyweixinrealgroupperformancelist() {
				this.tableloading = true
				let data = {
					Keywords: this.keywords,
					IsDissolution: this.groupstate,
					Skip: (this.currentPage - 1) * this.sizepage,
					Take: this.sizepage,
				}
				let result = await qyweixincutomerqyweixinrealgroupperformancelist(data)
				if (result.IsSuccess) {
					// console.log(result.Result)
					this.tableList = result.Result.Results
					this.total = result.Result.Total
				}
				this.tableloading = false
			},
			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp
						if (this.isauthorization) {
							this.getqyweixinrealgroupperformancelist()
						}
					}
				} finally {
					this.pageloading = false
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.FlexRow {
		display: flex;
		flex-direction: row;
	}
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
