import { render, staticRenderFns } from "./groupPerformance.vue?vue&type=template&id=1b18f8d5&scoped=true"
import script from "./groupPerformance.vue?vue&type=script&lang=js"
export * from "./groupPerformance.vue?vue&type=script&lang=js"
import style0 from "./groupPerformance.vue?vue&type=style&index=0&id=1b18f8d5&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b18f8d5",
  null
  
)

export default component.exports